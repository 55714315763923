:root {
  --base-size: 30px;
  --m-p-size: 5px;
  --remote-arrow-color: #7a7a7a;
  --remote-border-width: 5px;
  --background-effect: #4287f5;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

*.hidden {
  visibility: collapse;
  display: none;
}

body,
html,
#root {
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}

.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin-top: calc(var(--m-p-size) * 2);
}

.footer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  bottom: 0;
  margin-bottom: calc(var(--m-p-size) * 2);
}

.footer > button {
  outline: none;
  background: none;
  font-size: 1.17em;
  font-weight: bold;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  height: max-content;
  border: none;
  width: 100%;
}

.button {
  padding: var(--m-p-size);
  font-size: calc(var(--base-size) / 1.5);
}

.screen {
  height: 100%;
  width: 100%;
  display: flex;
  place-items: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;
}

form {
  display: flex;
  flex-direction: column;
  gap: calc(var(--m-p-size) * 2);
}

form > input {
  padding: var(--m-p-size);
  font-size: calc(var(--base-size) / 1.5);
}

form > label {
  display: flex;
  align-items: center;
  gap: var(--m-p-size);
}

form label,
form input[type="checkbox"] {
  font-size: calc(var(--base-size) / 1.6);
}

.history {
  overflow: scroll;
  width: auto;
  height: 30%;
  padding: 3%;
  transform: rotate(180deg);
}

.history-message {
  display: table-cell;
  transform: rotate(180deg);
}

.bubble {
  --bubble-color: lightyellow;
  padding: calc(var(--m-p-size) * 1.5);
  display: grid;
  grid-template-columns: min-content 1fr;
  row-gap: calc(var(--m-p-size) / 2);
  column-gap: calc(var(--m-p-size) * 1.5);
  position: relative;
  margin: 20px;
  overflow: hidden;
  font-weight: bold;
  font-size: smaller;
}
.bubble .text {
  width: 200px;
  padding: calc(var(--m-p-size) * 1.5);
  height: auto;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  border: 1px solid black;
}
.bubble .message {
  grid-column-start: 2;
}
.bubble .username {
  text-transform: capitalize;
  font-style: italic;
  font-weight: lighter;
}

.camera {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: flex-end;
  margin: calc(var(--m-p-size) * 2);
  gap: var(--m-p-size);
}

.camera .icon-button {
  align-self: end;
}

.profile-image {
  border-radius: 50%;
  overflow: hidden;
}

.icon-button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: 10px;
  box-shadow: 0px 0px 10px -3px rgb(0 0 0 / 50%);
}

.bubble .profile-image {
  --size: 40px;
  width: var(--size);
  height: var(--size);
}

.profile-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

@keyframes keyPressAnim {
  to {
    background-color: var(--background-effect);
  }
}

.system-message {
  text-transform: lowercase;
}

/* .message {
  display: grid;
  flex-direction: row;
  place-items: center;
}

.message > *:first-child {
  margin-bottom: calc(var(--m-p-size) * 2);
} */

.spinner-container {
  position: relative;
  width: 40px;
  height: 40px;
  margin-bottom: var(--m-p-size);
}
.spinner-container > i {
  position: absolute;
  animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite;
}
.spinner-container > i:nth-child(1) {
  font-size: 20px;
  top: -15px;
  right: -10px;
}
.spinner-container > i:nth-child(2) {
  font-size: 40px;
}
.spinner-container > i:nth-child(3) {
  font-size: 30px;
  top: -25px;
  left: -15px;
}

#spinner {
  display: inline-block;
  width: calc(var(--base-size) * 2);
  height: calc(var(--base-size) * 2);
  border: var(--m-p-size) solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@media (orientation: landscape) {
  .remote {
    grid-template-areas:
      "navigation home"
      "navigation back";
  }

  .navigation {
    width: 65vmin;
    height: 65vmin;
  }
  .button.home,
  .button.back {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
